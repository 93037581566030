import React from "react";
import PropTypes from "prop-types";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import JobDetailsContainer from "../containers/job/job-details";
import JobData from "../data/job.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const JobDetailsPage = ({
    match: {
        params: { id },
    },
}) => {
    const jobId = parseInt(id, 10);
    const data = JobData.filter((jobItem) => jobItem.id === jobId);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="BunnySpire LLC – Job" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="We are hiring"
                        excerpt="We are always open to hearing from technical and non-technical talent in countries worldwide."
                        image="/images/blog/banner.png"
                    />
                    <JobDetailsContainer data={data[0]} />
                    <NewsletterArea />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

JobDetailsPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

export default JobDetailsPage;
