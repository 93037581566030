import { useState } from "react";
import PropTypes from "prop-types";
import {
    dispatchControlEvent,
    DocumentCustomEvent,
    ControlEventInstruction,
} from "@innovatrics/dot-document-auto-capture/events";

import DocumentCamera from "./DocumentCamera";
import DocumentUi from "./DocumentUi";

const DocumentAutoCapture = ({ onPhotoTaken, onError, onBackClick }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handlePhotoTaken = (image, data) => {
        setIsButtonDisabled(false);
        onPhotoTaken(image, data);
    };

    const handleContinueDetection = () => {
        dispatchControlEvent(
            DocumentCustomEvent.CONTROL,
            ControlEventInstruction.CONTINUE_DETECTION
        );

        setIsButtonDisabled(true);
    };

    return (
        <>
            <h2>Document auto capture</h2>
            <div>
                <button
                    onClick={handleContinueDetection}
                    disabled={isButtonDisabled}
                >
                    Continue detection
                </button>

                <button onClick={onBackClick}>Back</button>
            </div>
            {/* parent container must have position: relative */}
            <div>
                <DocumentCamera
                    imageType="png"
                    cameraFacing="environment"
                    onPhotoTaken={handlePhotoTaken}
                    onError={onError}
                />
                <DocumentUi showCameraButtons />
            </div>
        </>
    );
};

DocumentAutoCapture.propTypes = {
    onPhotoTaken: PropTypes.func,
    onError: PropTypes.func,
    onBackClick: PropTypes.func,
};

export default DocumentAutoCapture;
