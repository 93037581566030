import React from "react";
import PropTypes from "prop-types";

function PhotoResult({ photoUrl = "" }) {
    return (
        <div className={styles.container}>
            <img alt="Web component result" src={photoUrl} />
        </div>
    );
}

PhotoResult.propTypes = {
    photoUrl: PropTypes.string,
};

export default PhotoResult;
