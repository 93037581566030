import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const JobTag = ({ tags }) => {
    return (
        <div className="widget-tags">
            {tags.map((single, i) => {
                return (
                    <span key={i} className="widget-tag-link">
                        {single}
                    </span>
                );
            })}
        </div>
    );
};

JobTag.propTypes = {
    tags: PropTypes.array,
};

export default JobTag;
