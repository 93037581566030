import { useCallback, useState } from "react";
import ComponentSelect from "../components/selfie/ComponentSelect";
import DocumentAutoCapture from "../components/selfie/DocumentAutoCapture";
import FaceAutoCapture from "../components/selfie/FaceAutoCapture";
import MagnifEyeLiveness from "../components/selfie/MagnifEyeLiveness";
import PhotoResult from "../components/selfie/PhotoResult";
import { Step } from "../components/selfie/step";

const Selfie = () => {
    const [step, setStep] = useState(Step.SELECT_COMPONENT);
    const [photoUrl, setPhotoUrl] = useState();

    const handlePhotoTaken = (image, data, content) => {
        const imageUrl = URL.createObjectURL(image);
        setPhotoUrl(imageUrl);
    };

    const handleDocumentPhotoTaken = (image, data) => {
        handlePhotoTaken(image, data);
    };

    const handleFaceCapturePhotoTaken = (image, data) => {
        handlePhotoTaken(image, data);
    };

    const handleMagnifEyeComplete = ({ image, data }, content) => {
        handlePhotoTaken(image, data, content);
    };

    const handleError = useCallback((error) => {
        alert(error);
    }, []);

    const handleBackClick = () => {
        setPhotoUrl(undefined);
        setStep(Step.SELECT_COMPONENT);
    };

    const renderStep = (currentStep) => {
        switch (currentStep) {
            case Step.DOCUMENT_CAPTURE:
                return (
                    <>
                        <DocumentAutoCapture
                            onPhotoTaken={handleDocumentPhotoTaken}
                            onError={handleError}
                            onBackClick={handleBackClick}
                        />
                        {photoUrl && <PhotoResult photoUrl={photoUrl} />}
                    </>
                );
            case Step.FACE_CAPTURE:
                return (
                    <>
                        <FaceAutoCapture
                            onPhotoTaken={handleFaceCapturePhotoTaken}
                            onError={handleError}
                            onBackClick={handleBackClick}
                        />
                        {photoUrl && <PhotoResult photoUrl={photoUrl} />}
                    </>
                );
            case Step.MAGNIFEYE_LIVENESS:
                return (
                    <>
                        <MagnifEyeLiveness
                            onComplete={handleMagnifEyeComplete}
                            onError={handleError}
                            onBackClick={handleBackClick}
                        />
                        {photoUrl && <PhotoResult photoUrl={photoUrl} />}
                    </>
                );
            default:
                return <ComponentSelect setStep={setStep} />;
        }
    };

    return (
        <div>
            <h1>DOT Web Components Integration</h1>
            {renderStep(step)}
        </div>
    );
};

export default Selfie;
