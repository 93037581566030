import React from "react";
import PropTypes from "prop-types";
import SidebarCategories from "../../../components/sidebar/categories";
import SidebarSearch from "../../../components/sidebar/search";
import SidebarTag from "../../../components/sidebar/sidbar-tag";
import SidebarArchive from "../../../components/sidebar/sidebar-archive";
import SidebarPost from "../../../components/sidebar/sidebar-post";
import SidebarTitle from "../../../components/sidebar/sidebar-title";
import JobData from "../../../data/job.json";
import JobDetailsWrap from "../../../components/job-details";
import ApplyForm from "../../../components/apply-form";

const JobDetailsContainer = ({ data }) => {
    return (
        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-8 col-lg-8 mb-7">
                        <div className="blog-details-content">
                            <JobDetailsWrap data={data} />
                            <div className="blog-comments ">
                                <h3 className="blog-comment-title">
                                    Apply For {data.title}
                                </h3>
                                <p>
                                    We are always open to hearing from technical
                                    and non-technical talent in countries
                                    worldwide. If you would like to learn more
                                    about this position and project, apply now!
                                    Connect with a recruiter today!
                                </p>
                            </div>

                            <ApplyForm />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 mb-7 offset-xl-1">
                        <div className="widget-wrapper widget-wrapper-nl">
                            <div className="sidebar-widget">
                                <SidebarTitle title="Search" />
                                <SidebarSearch />
                            </div>

                            <div className="sidebar-widget">
                                <SidebarTitle title="Categories" />
                                <SidebarCategories data={JobData} />
                            </div>
                            <div className="sidebar-widget">
                                <SidebarTitle title="popular post" />
                                <SidebarPost data={JobData} />
                            </div>

                            <div className="sidebar-widget">
                                <SidebarTitle title="Archive" />
                                <SidebarArchive data={JobData} />
                            </div>

                            <div className="sidebar-widget">
                                <SidebarTitle title="Tags" />
                                <SidebarTag data={JobData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

JobDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default JobDetailsContainer;
