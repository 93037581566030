import React from "react";
import PropTypes from "prop-types";
import MagnifEyeLivenessCamera from "./MagnifEyeLivenessCamera";
import MagnifEyeLivenessUi from "./MagnifEyeLivenessUi";

const MagnifEyeLiveness = ({ onBackClick, onComplete, onError }) => {
    return (
        <>
            <h2>MagnifEye Liveness</h2>
            <div>
                <button onClick={onBackClick}>Back</button>
            </div>
            <div>
                <MagnifEyeLivenessCamera
                    onComplete={onComplete}
                    onError={onError}
                />
                <MagnifEyeLivenessUi showCameraButtons />
            </div>
        </>
    );
};

MagnifEyeLiveness.propTypes = {
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    onBackClick: PropTypes.func,
};

export default MagnifEyeLiveness;
