import {
    dispatchControlEvent,
    FaceCustomEvent,
    ControlEventInstruction,
} from "@innovatrics/dot-face-auto-capture/events";
import { useState } from "react";
import PropTypes from "prop-types";
import FaceCamera from "./FaceCamera";
import FaceUi from "./FaceUi";

function FaceAutoCapture({ onPhotoTaken, onError, onBackClick }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handlePhotoTaken = (image, data) => {
        setIsButtonDisabled(false);
        onPhotoTaken(image, data);
    };

    const handleContinueDetection = () => {
        dispatchControlEvent(
            FaceCustomEvent.CONTROL,
            ControlEventInstruction.CONTINUE_DETECTION
        );

        setIsButtonDisabled(true);
    };
    return (
        <>
            <div>
                <FaceCamera
                    imageType="png"
                    cameraFacing="user"
                    onPhotoTaken={handlePhotoTaken}
                    onError={onError}
                />
                <FaceUi showCameraButtons />
            </div>
        </>
    );
}

FaceAutoCapture.propTypes = {
    onPhotoTaken: PropTypes.func,
    onError: PropTypes.func,
    onBackClick: PropTypes.func,
};

export default FaceAutoCapture;
