import React from "react";
import JobItemContainer from "../containers/job/job-item";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import JobData from "../data/job.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const JobPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="BunnySpire LLC – Job" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="We are hiring"
                        excerpt="We are always open to hearing from technical and non-technical talent in countries worldwide."
                        image="./images/blog/banner.png"
                    />
                    <JobItemContainer data={JobData} />
                    <NewsletterArea />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default JobPage;
