import React from "react";
import PropTypes from "prop-types";

import { Step } from "./step";

const ComponentSelect = ({ setStep }) => {
    const handleDocumentClick = () => {
        setStep(Step.DOCUMENT_CAPTURE);
    };

    const handleFaceClick = () => {
        setStep(Step.FACE_CAPTURE);
    };

    const handleMagnifEyeLivenessClick = () => {
        setStep(Step.MAGNIFEYE_LIVENESS);
    };

    return (
        <div>
            <button onClick={handleDocumentClick}>Document</button>
            <button onClick={handleFaceClick}>Face</button>
            <button onClick={handleMagnifEyeLivenessClick}>
                MagnifEye Liveness
            </button>
        </div>
    );
};

ComponentSelect.propTypes = {
    setStep: PropTypes.func,
};

export default ComponentSelect;
